import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"
import axios from 'axios'
import VueAxios from 'vue-axios'

//import Home from './components/HelloWorld.vue'
const Home = ()=> import('./components/HelloWorld.vue')
//import Denunciarecursoagua from './components/Denunciarecursoagua.vue'
const Denunciarecursoagua = ()=> import('./components/Denunciarecursoagua.vue')
const Denunciacorrupcion = ()=> import('./components/Denunciacorrupcion.vue')
const Solicitudes = ()=> import('./components/Solicitudes.vue')
const SolicitudesDP = ()=> import('./components/SolicitudesDP.vue')
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false


const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Home
  },
  {
    path: '/pqrds',
    name: 'pqrds',
    component: Denunciarecursoagua
  },
  {
    path: '/corrupcion',
    name: 'corrupcion',
    component: Denunciacorrupcion
  },
  {
    path: '/solicitud',
    name: 'solicitud',
    component: Solicitudes
  },
  {
    path: '/solicitudDP',
    name: 'solicitudDP',
    component: SolicitudesDP
  },
]

const router = new VueRouter({
  mode: "history",
  routes
});



new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
